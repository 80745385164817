// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "../../../style.react.scss";

// Default Layout themes
@import "../../../themes/layout/header/base/light.scss";
@import "../../../themes/layout/header/menu/light.scss";
@import "../../../themes/layout/brand/dark.scss";
@import "../../../themes/layout/aside/dark.scss";

// Header themes
// Light
//@import "../../../themes/layout/header/base/light.scss";
// Dark
// @import "../../../themes/layout/header/base/dark.scss";
  
// Header Menu themes
// Light
//@import "../../../themes/layout/header/menu/light.scss";
// Dark
// @import "../../../themes/layout/header/menu/dark.scss";

// Brand themes
.brand-dark {
  @import "../../../themes/layout/brand/dark.scss";
}
// Light
.brand-light {
  @import "../../../themes/layout/brand/light.scss";
}

// Aside themes
// Dark
//@import "../../../themes/layout/aside/dark.scss";
// Light
// @import "../../../themes/layout/aside/light.scss";

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }

.example-highlight > pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem; 
  code { 
      overflow-y: auto;
      display: block;

      span {
        background: none transparent !important;
      }
  }
}

.json > pre {
  background: none #fff !important;

}

.example-code > .json {
  background: none #fff !important;
}

.symbol.symbol-45 > svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.react-bootstrap-table {
  th {
    outline: none;
   
    &.sortable {  
      .svg-icon-sort {
        opacity: 0;
      }
  
      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }    
  }

  .table.table-head-custom thead tr {
    .sortable-active {
        color: $primary !important;
    }
  }
}

.cursor-default {
  cursor: default !important;
}

.display-block {
  display: block;
}

//
// Login 1
// Pages SASS files are compiled into separate css files
//


// Initialization of global variables, mixins and functions
@import "../../../init";

.login.login-1 {
    // Form modes
    .login-signin,
    .login-signup,
    .login-forgot {
        display: none;
    }

    &.login-signin-on {
        .login-signup {
            display: none;
        }

        .login-signin {
            display: block;
        }

        .login-forgot {
            display: none;
        }
    }

    &.login-signup-on {
        .login-signup {
            display: block;
        }

        .login-signin {
            display: none;
        }

        .login-forgot {
            display: none;
        }
    }

    &.login-forgot-on {
        .login-signup {
            display: none;
        }

        .login-signin {
            display: none;
        }

        .login-forgot {
            display: block;
        }
    }
}

// Desktop view
@include media-breakpoint-up(lg) {
    .login.login-1 {
        .login-aside {
            width: 100%;
            max-width: 600px;
        }

        .login-form {
            width: 100%;
            max-width: 400px;
        }
    }
}

// Tablet and Mobile mode
@include media-breakpoint-between(lg, xl) {
    .login.login-1 {
        .login-aside {
            width: 100%;
            max-width: 400px;
        }
    }
}

// Tablet mode
@include media-breakpoint-down(md) {
    .login.login-1 {
        .login-form {
            width: 100%;
            max-width: 350px;
        }
    }
}

// Mobile mode
@include media-breakpoint-down(xs) {
    .login.login-1 {
        .login-form {
            width: 100%;
            max-width: 100%;
        }
    }
}
